import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { axiosRun } from "../utils/axiosWrap";
import { config } from "../config";
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Select from "@mui/material/Select";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from "@mui/material/InputLabel";
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Mailing = () => {
    const [checked, setChecked] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const [howSend, setHowSend] = useState('all');
    const [type, setType] = useState('register');
    const [openAlert, setOpenAlert] = useState(false);
    const [resultMailinig, setResultMailing] = useState({});
    const [openResult, setOpenResult] = useState(false);

    useEffect(() => {
        const getFList = async () => {
            const faculties = await axiosRun(config.apiLinks.getFacultiesList, 'GET');
            const mappedFaculties = {};
            faculties.forEach(item => {
                mappedFaculties[item.value] = item;
            })
            setFaculties(mappedFaculties);
        };
        getFList();
    }, []);

    useEffect(() => {
        if (Object.keys(faculties).length > 0) {
            setIsLoad(false);
        }
    }, [faculties]);

    useEffect(() => {
        if (Object.keys(resultMailinig).length > 0) {
            setOpenResult(true);
        }
    }, [resultMailinig])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleChangeSendType = (event) => {
        setHowSend(event.target.value);
    }

    const handleExecuteSending = async () => {
        setIsLoad(true);
        console.log("checked ", checked);
        const result = await axiosRun(config.apiLinks.executeMailing, 'POST', { howSend, checked, type });
        setChecked([]);
        setOpenAlert(true);
        setResultMailing(result);
        setIsLoad(false);
    }
    const handleHideMaillingResult = () => {
        
        setOpenResult(false);
        setResultMailing({});
    }

    return (
        <>
            {openResult && <Box sx={{ width: '100%' }}>
            
                    <Paper sx={{position: 'relative' ,p: 3}} in={openResult}>
                        <Typography sx={{ textAlign: 'center', fontSize: '22px', mb:4 }} component={'h2'}>Log result</Typography>
                        <IconButton sx={{position:'absolute', top: '0px', right: '0px'}}  size="large" onClick={handleHideMaillingResult}><CancelOutlinedIcon/></IconButton>
                            {Object.keys(resultMailinig.logs).length > 0 || Object.keys(resultMailinig.errors).length > 0 ? <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                                {Object.keys(resultMailinig.logs).length > 0 && (
                                    <Grid xs={12} md={6} item sx={{ background: '#EBFFF2', maxHeight: '50vh', overflow: 'auto', borderRadius: '10px' }}>
                                        <div><pre>{JSON.stringify(resultMailinig.logs, null, 2)
                                            .replaceAll("[", "")
                                            .replaceAll("{", "")
                                            .replaceAll("}", "")
                                            .replaceAll("]", "")
                                            .replaceAll('"', "")
                                            .replaceAll(',', "")}</pre></div>
                                    </Grid>
                                )}
                                {Object.keys(resultMailinig.errors).length > 0 && (
                                    <Grid xs={12} md={6} sx={{ background: '#FFEBEB', maxHeight: '50vh', overflow: 'auto', borderRadius: '10px' }} item>
                                        <div><pre>{JSON.stringify(resultMailinig.errors, null, 2)
                                            .replaceAll("[", "")
                                            .replaceAll("{", "")
                                            .replaceAll("}", "")
                                            .replaceAll("]", "")
                                            .replaceAll('"', "")
                                            .replaceAll(',', "")}</pre></div>
                                    </Grid>
                                )}

                            </Grid> :
                                <div>
                                    No email was founded for mailing
                                </div>}
                   
                    </Paper>

             
            </Box>}
            <Grid container spacing={3}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoad}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    open={openAlert}
                    autoHideDuration={7000}
                    onClose={() => {
                        setOpenAlert(false);
                    }}
                >
                    <Alert
                        onClose={() => {
                            setOpenAlert(false);
                        }}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        Перевірте результати розсилки
                    </Alert>
                </Snackbar>
                {Object.keys(resultMailinig).length > 0 && <Grid item xs={12} l={12} xl={12}>

                </Grid>}
                <Grid xs={12} md={4} item>
                    <Typography sx={{ mb: 2 }}>Виберіть факультет</Typography>
                    <Paper sx={{ maxHeight: '80vh', overflow: 'auto' }}>
                        <List dense component="div" role="list">
                            {Object.keys(faculties).map((value) => {
                                const itemF = faculties[value];
                                const labelId = `transfer-list-item-${value}-label`;
                                return (
                                    <ListItem
                                        key={value}
                                        role="listitem"
                                        button
                                        onClick={handleToggle(value)}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={checked.indexOf(value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={` ${itemF.name} `} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Paper>
                </Grid>
                <Grid xs={12} md={5} item>
                    <FormControl>
                        <FormLabel sx={{ color: '#222', mb: 2 }} >Виберіть тип відправки</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="howSend"
                            value={howSend}
                            onChange={handleChangeSendType}
                        >
                            <FormControlLabel value="all" control={<Radio />} label="Відправити всим студентам вибраних спеціальностей" />
                            <FormControlLabel value="notSended" control={<Radio />} label="Відправити ТІЛЬКИ тим студентам яким не було відправлено раніше" />
                            <FormControlLabel value="sended" control={<Radio />} label="Відправити ТІЛЬКИ тим студентам яким уже було відправлено раніше" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{mt:'2%'}}>
                        <InputLabel id="typeMail">Тип листа</InputLabel>
                        <Select
                            labelId="typeMail"
                            id="typeMail"
                            value={type}
                            label="Тип листа"
                            onChange={(e)=>{
                                setType(e.target.value)
                            }}
                        >
                            <MenuItem value={'register'}>Посилання на реєстрацію</MenuItem>
                            <MenuItem value={'declaration'}>Підпис декларації</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} md={3} item>
                    <Button

                        variant="contained"

                        onClick={handleExecuteSending}
                        disabled={checked.length === 0}
                        aria-label="move all right"
                    >
                        Запустити відправку
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}